import { Navbar, Container } from "react-bootstrap";

export default function NavBar() {

  return (   
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="https://cashifi.com/">
            <img src="/logo.png" width="20%" alt="Cashifi"/>
            {/* <b style={{color: "#46B249"}}>Cashifi</b> */}
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
  );
}