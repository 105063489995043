import { useEffect } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";

declare global {
  interface Window {
    gtag: any;
  }
}

export default function ApplySuccessPage() {
  useEffect(() => {
    document.title = "Cashifi Loan Application - Success";
    window.gtag('config', 'G-CK7FTRPNSD');
    window.gtag('config', 'AW-10932971829');
    window.gtag('event', 'conversion', { 'send_to': 'G-CK7FTRPNSD/Cashifi_Success' });
    window.gtag('event', 'conversion', { 'send_to': 'AW-10932971829/jgbwCI78zM0DELXSn90o' });
  });

  return (
    <div className="page">
      <div className="jumbotron">
        <Container>
          <Row>
            <Col className="jb-col" md={10}>
              <h1>Cashifi's Easy Online Application</h1>
              <h4>To see if you qualify to receive funding for any business loan,<br />begin by filling out the form below.</h4>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="main">
        <Row>
          <Col md={12}>
            <ProgressBar now={10} max={10} label="100%" />
          </Col>
        </Row>
        <Row className="pd-t">
          <Col md={12}>
            <div className="alert alert-success" role="alert">
              Successful submission!<br />
              If you qualify to receive funding for any business loan, a partner will be reaching out to you.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
