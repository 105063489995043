import { useEffect, useState } from "react";
import { Container, Row, Table, Form, Modal, Button, Col } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import dayjs from "dayjs";
import { Lead } from "../../../../store/actions/lead";
import { attemptDeleteLead } from "../../../../store/thunks/lead";
import { attemptGetAllLeads } from "src/store/thunks/admin";

export default function LeadsPanel() {
  const adminData = useAppSelector((state) => state.admin);
  const { isSuperAdmin } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currLead, setCurrLead] = useState<Lead>();
  const [leadsFilter, setLeadsFilter] = useState<string>("today");
  const [leadsSort, setLeadsSort] = useState<string>("date-asc");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [leadsToShow, setLeadsToShow] = useState<Array<Lead>>([]);

  const [leadToDelete, setLeadToDelete] = useState<Lead | undefined>(undefined);
  const handleCloseDeleteModal = () => setLeadToDelete(undefined);

  const handleDeleteLead = async (leadId: string) => {
    if (leadId) {
      await dispatch(attemptDeleteLead(leadId));
      await dispatch(attemptGetAllLeads());
    }
  };

  useEffect(() => {
    const loanAmounts = {
      "$10k-$30k": 1,
      "$30k-75k": 2,
      "$75k-150k": 3,
      "$150k-250k": 4,
      "$250k+": 5
    }

    const filterAndSortLeads = (allLeads: Array<Lead>, filterType: string, sortType: string) => {
      let leads: Array<Lead>;
      if (filterType === "today")
        leads = allLeads.filter((lead) => dayjs().isSame(lead.submissionDate, "day"));
      else if (filterType === "month")
        leads = allLeads.filter((lead) => dayjs().isSame(lead.submissionDate, "month"));
      else if (filterType === "year")
        leads = allLeads.filter((lead) => dayjs().isSame(lead.submissionDate, "year"));
      else
        leads = allLeads;

      if (sortType === "date-desc")
        leads = leads.sort((lead1, lead2) => (dayjs(lead1.submissionDate).diff(dayjs(lead2.submissionDate))));
      else if (sortType === "date-asc")
        leads = leads.sort((lead1, lead2) => (dayjs(lead2.submissionDate).diff(dayjs(lead1.submissionDate))));
      else if (sortType === "loan-asc")
        leads = leads.sort((lead1, lead2) => (loanAmounts[lead2.loanAmount] - loanAmounts[lead1.loanAmount]));
      else if (sortType === "loan-desc")
        leads = leads.sort((lead1, lead2) => (loanAmounts[lead1.loanAmount] - loanAmounts[lead2.loanAmount]));
      return leads;
    };

    setLeadsToShow(filterAndSortLeads(adminData.leads, leadsFilter, leadsSort));
  }, [adminData.leads, leadsFilter, leadsSort]);

  return (
    <Container>
      <Modal show={leadToDelete !== undefined} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>{leadToDelete?.businessName}</b> will be deleted permanently.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => { if (currLead && currLead._id) { handleDeleteLead(currLead._id); } handleCloseDeleteModal(); }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Lead Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currLead ?
            <div>
              <p>Name: {currLead.firstName} {currLead?.lastName}</p>
              <p>Email: {currLead.email}</p>
              <p>Submission Date: {dayjs(currLead.submissionDate).format("MM/DD/YYYY").toString()}</p>
              <p>Business Type: {currLead.businessType}</p>
              <p>Loan Amount: {currLead.loanAmount}</p>
              <p>Funding Purpose: {currLead.fundingPurpose}</p>
              <p>Business Start Date: {dayjs(currLead.businessStartDate).format("MM/DD/YYYY").toString()}</p>
              <p>Annual Revenue: {currLead.annualRevenue}</p>
              <p>Credit Score: {currLead.creditScore}</p>
              <p>Business Name: {currLead.businessName}</p>
              <p>Industry: {currLead.industry}</p>
              <p>Deposits Last Month: {formatter.format(Number(currLead.lastMonthDeposit).valueOf())}</p>
              <p>Business Zip Code: {currLead.businessZipCode}</p>
              <p>Business Address: {currLead.address ?? ""}</p>
              <p>Preferred Funding Date: {dayjs(currLead.fundingTime).format("MM/DD/YYYY").toString()}</p>
              <p>Frontend Source: {currLead.frontendId}</p>
              <p>Source Url: {currLead.sourceUrl ?? ""}</p>
            </div>
            : <></>}
        </Modal.Body>
        <Modal.Footer>
          {isSuperAdmin ?
            <Button variant="danger" onClick={() => { if (currLead && currLead._id) { handleCloseModal(); setLeadToDelete(currLead); } }}>
              Delete
            </Button>
            : <></>}
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Row>
        <h2 id="admin-h2">Leads Dashboard</h2>
      </Row>
      <br />
      <Row>
        <Container>
          <Row>
            <Col md={3}>
              <Form.Select aria-label="Lead filtering" className="sort-select" onChange={(value) => setLeadsFilter(value.target.value)}>
                <option value="today">Today</option>
                <option value="month">This Month</option>
                <option value="year">This Year</option>
                <option value="all">All Time</option>
              </Form.Select>
            </Col>
            <Col md={3}>
              <Form.Select aria-label="Lead sorting" className="sort-select" onChange={(value) => setLeadsSort(value.target.value)}>
                <option value="date-desc">By Date (Newest First)</option>
                <option value="date-asc">By Date (Oldest First)</option>
                <option value="loan-desc">By Loan Amount (Highest First)</option>
                <option value="loan-asc">By Loan Amount (Lowest First)</option>
              </Form.Select>
            </Col>
          </Row>
          <br />
          <Row>
            {leadsToShow.length > 0 ?
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Lead Name</th>
                    <th>Email</th>
                    <th>Business Name</th>
                    <th>Loan Amount</th>
                    <th>Annual Revenue</th>
                    <th>Frontend Source</th>
                    <th>Submission Date</th>
                  </tr>
                </thead>
                <tbody>
                  {leadsToShow.map((lead, i) =>
                    <tr key={i} onClick={() => { setCurrLead(lead); handleShowModal(); }} className="admin-tablerow">
                      <td>{lead.firstName} {lead.lastName}</td>
                      <td>{lead.email}</td>
                      <td>{lead.businessName}</td>
                      <td>{lead.loanAmount}</td>
                      <td>{lead.annualRevenue}</td>
                      <td>{lead.frontendId ?? "N/A"}</td>
                      <td>{dayjs(lead.submissionDate).format("MM/DD/YYYY").toString()}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              : <>
                <h4>No leads yet {{ "today": "today", "month": "this month", "year": "this year", "all": "all time" }[leadsFilter]}!</h4>
                <br /><p>Change the search filter above to display more results.</p>
              </>}
          </Row>
        </Container>
      </Row>
    </Container>
  );
}
