import { useEffect, useState } from "react";
import { Container, Row, Table, Button, Form, Col, Modal } from "react-bootstrap";
import { Company, LeadClass } from "../../../../../store/actions/company";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileExport } from "@fortawesome/free-solid-svg-icons";
import CompanyAddLeadType from "../../../../../components/CompanyAdmin/CompanyAddLeadType";
import CompanyLeadTypeDataRow from "../../../../../components/CompanyAdmin/CompanyLeadTypeDataRow";
import { attemptDeleteCompany } from "../../../../../store/thunks/company";
import { attemptGetAllCompanies } from "../../../../../store/thunks/admin";
import { getLeadsExport } from "../../../../../api";
import CompanyInfoTable from "../../../../../components/CompanyInfoTable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore)

export interface IProps {
  company: Company;
  showCompanyPanel: any;
  setSuccessMsg: any;
}

export default function CompanyPage({ company, showCompanyPanel, setSuccessMsg }: IProps) {
  const userIsSuperAdmin = useAppSelector((state) => state.user.isSuperAdmin);
  const dispatch = useAppDispatch();
  const adminCompaniesData = useAppSelector((state) => state.admin.companies);
  const [companyLeadTypes, setCompanyLeadTypes] = useState<Array<LeadClass>>([]);
  const [addLeadChoices, setAddLeadChoices] = useState<Array<string>>([]);
  const [deleteCompanyError, setDeleteCompanyError] = useState<string>();
  const [dateFilterData, setDateFilterData] = useState({ "dateFrom": dayjs().subtract(1, "month"), "dateTo": dayjs() });
  const leadTypeRanks = { "Cashifi Startup": 0, "Cashifi Startup HC": 1, "Bronze": 2, "Silver": 3, "Gold": 4, "Platinum": 5 };
  const [dateFilterError, setDateFilterError] = useState<string | undefined>("");

  const handleInputData = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = (event.target as HTMLInputElement).value.trim();
    const dayjsObj = dayjs(value);

    setDateFilterData(prevState => ({
      ...prevState,
      [input]: dayjsObj
    }));

  };

  const [companyDeleteRequest, setCompanyDeleteRequest] = useState<boolean>(false);
  const handleShowDeleteModal = () => setCompanyDeleteRequest(true);
  const handleCloseDeleteModal = () => setCompanyDeleteRequest(false);

  const handleDeleteCompany = async () => {
    if (company._id) {
      await dispatch(attemptDeleteCompany(company._id))
        .then(() => { setDeleteCompanyError(undefined); })
        .catch(() => { setDeleteCompanyError("Error deleting company. Please contact Cashifi if this problem persists."); });
      setSuccessMsg("Sucessfully deleted " + company.name + "!");
      await dispatch(attemptGetAllCompanies());
      showCompanyPanel();
    } else {
      setDeleteCompanyError("Error. Company does not exist.");
    }
  };

  const exportLeadTypeDetails = async () => {
    setDateFilterError(undefined);
    if (!dateFilterData["dateFrom"].isSameOrBefore(dateFilterData["dateTo"])) {
      setDateFilterError("Start date must occur before end date.");
      return;
    }

    if (company._id && adminCompaniesData) {
      const leadTypes = ["Cashifi Startup", "Cashifi Startup HC", "Bronze", "Silver", "Gold", "Platinum"];
      const heading = [["Email", "Name", "Phone Number", "Business Name", "Submission Date", "Business Type", "Loan Amount", "Funding Purpose", "Business Start Date", "Annual Revenue", "Credit Score", "Industry", "Last Month's Deposits", "Zip Code", "Funding Date", "Analytics GL", "Analytics GA"]];
      const workbook = XLSX.utils.book_new();
      for (const leadType of leadTypes) {
        const sentLeadIds = companyLeadTypes.find(type => type.leadType === leadType)?.sentLeads ?? [];
        const resLeadType = await getLeadsExport(sentLeadIds.filter((sentLead) => dayjs(sentLead.sentDate).isBetween(dateFilterData["dateFrom"], dateFilterData["dateTo"], "day", "[]")).map((lead) => lead.leadObjectId));
        const rows = resLeadType.data.matchingLeads.map(matchingLead => ({
          email: matchingLead.email,
          name: matchingLead.firstName + " " + matchingLead.lastName,
          phoneNumber: matchingLead.phoneNumber,
          businessName: matchingLead.businessName,
          submissionDate: matchingLead.submissionDate,
          businessType: matchingLead.businessType,
          loanAmount: matchingLead.loanAmount,
          fundingPurpose: matchingLead.fundingPurpose,
          startDate: matchingLead.businessStartDate,
          annualRevenue: matchingLead.annualRevenue,
          creditScore: matchingLead.creditScore,
          industry: matchingLead.industry,
          lastDeposits: matchingLead.lastMonthDeposit,
          zipCode: matchingLead.businessZipCode,
          fundingDate: matchingLead.fundingTime,
          analyticsGL: matchingLead.analyticsGLQuery,
          analyticsGA: matchingLead.analyticsGAQuery
        }));
        const worksheet = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.sheet_add_aoa(worksheet, heading);
        worksheet["!cols"] = [{wch: 20}, {wch: 15}, {wch: 14}, {wch: 15}, {wch: 10}, {wch: 12}, {wch: 11}, {wch: 10}, {wch: 10}, {wch: 12}, {wch: 14}];
        // if (worksheet && worksheet['!ref']) { 
        //   // worksheet['!ref'] = worksheet['!ref'].replace('T', 'S'); 
        //   // worksheet['!ref'] = worksheet['!ref'].replace('T', 'S'); 
        // }
        XLSX.utils.book_append_sheet(workbook, worksheet, ("Lead Class " + leadType));
      }
      const slashRegex = new RegExp("/", "g");
      const exportName = company.name.replace("/ /g", "-") + "_" + (dateFilterData["dateFrom"]).toDate().toLocaleDateString().replace(slashRegex, "-") + "_" + (dateFilterData["dateTo"]).toDate().toLocaleDateString().replace(slashRegex, "-");
      XLSX.writeFile(workbook, exportName + ".xlsx");
    }
  }

  useEffect(() => {
    const leadTypeClasses = ["Cashifi Startup", "Cashifi Startup HC", "Bronze", "Silver", "Gold", "Platinum"];;
    if (adminCompaniesData) {
      const foundCompany = adminCompaniesData.find(c => c._id === company._id);
      if (foundCompany !== undefined && foundCompany.leadTypes) {
        const hasLeadTypeClasses = foundCompany.leadTypes.map((type) => type.leadType);
        const choiceLeadTypes = leadTypeClasses.filter((t) => !hasLeadTypeClasses.includes(t));
        setCompanyLeadTypes(foundCompany.leadTypes);
        setAddLeadChoices(choiceLeadTypes);
      }
    }
  }, [adminCompaniesData, company._id]);

  const dateFromDisplay = dayjs(dateFilterData["dateFrom"]).format("YYYY-MM-DD");
  const dateToDisplay = dayjs(dateFilterData["dateTo"]).format("YYYY-MM-DD");
  return (
    <Container>
      <Modal show={companyDeleteRequest} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>{company.name}</b> will be deleted permanently.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
            <Button variant="danger" onClick={() => { handleDeleteCompany(); handleCloseDeleteModal(); }}>
              Delete
            </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <h2 id="admin-h2">Companies Dashboard</h2>
      </Row>
      <Row>
        <h5 id="admin-h5">
          <Button id="btn-link-style" onClick={showCompanyPanel}>
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
          </Button>
        </h5>
      </Row>
      <br />
      <Row>
        <Container>
          <Row>
            <h5 id="admin-h5">Company Information</h5>
            <CompanyInfoTable company={company} isSuperAdmin={userIsSuperAdmin} />
          </Row>
          <br />
          <Row>
            <h5 id="admin-h5">
              Lead Type Details
              <span className="m-1" />
              <Button id="btn-generic-style" onClick={exportLeadTypeDetails}><FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export</Button>
            </h5>
            <Container>
              <Row>
                <Col md={3}>
                  <Form.Control
                    name={"dateFrom"}
                    type={"date"}
                    onChange={handleInputData("dateFrom")}
                    placeholder={dateFromDisplay}
                    defaultValue={dateFromDisplay}
                  />
                </Col>
                <Col md={3}>
                  <Form.Control
                    name={"dateTo"}
                    type={"date"}
                    onChange={handleInputData("dateTo")}
                    placeholder={dateToDisplay}
                    defaultValue={dateToDisplay}
                  />
                </Col>
              </Row>
              {dateFilterError ? <><br /><div className="alert alert-danger" role="alert">{dateFilterError}</div></> : <></>}
              <p>Showing data from <b>{(dateFilterData["dateFrom"]).toDate().toLocaleDateString()}</b> to <b>{(dateFilterData["dateTo"]).toDate().toLocaleDateString()}</b></p>
              {/* <p>Showing data from <b>{String(dateFilterData["dateFrom"].toDate()).toString()}</b> to <b>{String((dateFilterData["dateTo"].toDate)).toString()}</b></p> */}
            </Container>
            {companyLeadTypes.length > 0 ?
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Lead Type</th>
                    <th>Sent Leads</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {companyLeadTypes.sort((a, b) => leadTypeRanks[a.leadType] - leadTypeRanks[b.leadType]).map((leadType, i) =>
                    <tr key={i + leadType.leadType} className="admin-tablerow">
                      <td>{leadType.leadType}</td>
                      <td>{leadType.sentLeads && leadType.sentLeads.length > 0 ? leadType.sentLeads.filter((sentLead) => dayjs(sentLead.sentDate).isBetween(dateFilterData["dateFrom"], dateFilterData["dateTo"], "day", "[]")).length : 0}</td>
                      <td>{leadType.isActive ? "Active" : "Inactive"}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              : <h6>No lead types yet!</h6>}
          </Row>
          <br />
          {userIsSuperAdmin ?
            <Row>
              <h5 id="admin-h5">Lead Data</h5>
              {companyLeadTypes.length > 0 ?
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th>Lead Type</th>
                      <th>Webhook</th>
                      <th>Status</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyLeadTypes.sort((a, b) => leadTypeRanks[a.leadType] - leadTypeRanks[b.leadType]).map((leadType, i) =>
                      <CompanyLeadTypeDataRow key={i + leadType.leadType} leadType={leadType} companyId={company._id ?? ""} />
                    )}
                  </tbody>
                </Table>
                : <h6>No lead types yet!</h6>}
              {userIsSuperAdmin && addLeadChoices.length > 0 ? addLeadChoices.map((type, i) =>
                <Container key={i + addLeadChoices.length + type}>
                  <Row style={{ "textAlign": "center" }}><CompanyAddLeadType leadTypeClass={type} companyId={company._id ?? ""} /></Row>
                </Container>
              ) : <></>
              }
            </Row>
            : <></>}
          {userIsSuperAdmin ?
            <Row>
              <Container style={{ "textAlign": "center" }}>
                <br />
                {deleteCompanyError ? <div className="alert alert-danger" role="alert">{deleteCompanyError}</div> : <></>}
                <Button variant="danger" style={{ width: "60%" }} onClick={handleShowDeleteModal}>Delete Company</Button>
                {/* <Button variant="danger" style={{ width: "60%" }} onClick={() => { handleDeleteCompany(); }}>Delete Company</Button> */}
              </Container>
            </Row>
            : <></>}
        </Container>
      </Row>
    </Container>
  );
}