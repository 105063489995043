import { useState } from "react";
import { Container, Row, Table, Button, Modal, Form } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../../../../store/hooks";
import { Company } from "../../../../../store/actions/company";
import { attemptCreateCompany } from "../../../../../store/thunks/company";
import CompanyPage from "./CompanyPage";
import { attemptGetAllCompanies } from "src/store/thunks/admin";

export default function CompaniesPanel() {
  const adminData = useAppSelector((state) => state.admin);
  const userIsSuperAdmin = useAppSelector((state) => state.user.isSuperAdmin);
  const dispatch = useAppDispatch();
  const [currCompany, setCurrCompany] = useState<Company>({
    name: "",
    contactEmail: "",
    contactPhone: ""
  });
  const [newCompany, setNewCompany] = useState<Company>({
    name: "",
    contactEmail: "",
    contactPhone: ""
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [saveCompanyError, setSaveCompanyError] = useState<string>();
  const [companySuccessMsg, setCompanySuccessMsg] = useState<string>();

  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCancelAddCompany = () => setNewCompany({ name: "", contactEmail: "", contactPhone: "" });
  const handleSaveAddCompany = async () => {
    if (newCompany && newCompany.name !== "" && newCompany.contactEmail !== "" && newCompany.contactPhone !== "") {
      setSaveCompanyError(undefined);
      const emailRegex = /\S+@\S+\.\S+/;
      const phoneRegex = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      if (emailRegex.test(newCompany.contactEmail)) {
        if (phoneRegex.test(newCompany.contactPhone)) {
          let company: Company = { name: newCompany.name, contactEmail: newCompany?.contactEmail, contactPhone: newCompany?.contactPhone };
          await dispatch(attemptCreateCompany(company)).catch(() => { setSaveCompanyError("Error submitting company. Please contact Cashifi if this problem persists."); });
          await dispatch(attemptGetAllCompanies());
          setNewCompany({name: "", contactEmail: "", contactPhone: ""});
          handleCloseAddModal();
        }
        else {
          setSaveCompanyError("Invalid phone number.");
        }
      } else {
        setSaveCompanyError("Invalid email.");
      }
    } else {
      setSaveCompanyError("Please fill out all company fields.");
    }
  };

  const showDefaultCompanyPanel = () => setCurrCompany({ name: "", contactEmail: "", contactPhone: "" });

  const defaultCompaniesPanel = () => {
    return (
      <Container>
        <Modal show={showAddModal} onHide={() => { handleCancelAddCompany(); handleCloseAddModal(); }}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Company</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {saveCompanyError ? <div className="alert alert-danger" role="alert">{saveCompanyError}</div> : <></>}
            <Table responsive>
              <tbody>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">Company Name:</Form.Label></td>
                  <td><Form.Control placeholder="Company name" value={newCompany?.name} onChange={(value) => setNewCompany({ name: value.target.value, contactEmail: newCompany.contactEmail, contactPhone: newCompany.contactPhone })} /></td>
                </tr>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">Contact Email:</Form.Label></td>
                  <td><Form.Control placeholder="Contact email" value={newCompany?.contactEmail} onChange={(value) => setNewCompany({ name: newCompany.name, contactEmail: value.target.value.trim(), contactPhone: newCompany.contactPhone })} /></td>
                </tr>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">Contact Phone:</Form.Label></td>
                  <td><Form.Control placeholder="Contact phone" value={newCompany?.contactPhone} onChange={(value) => setNewCompany({ name: newCompany.name, contactEmail: newCompany.contactEmail, contactPhone: value.target.value.trim() })} /></td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={async () => await handleSaveAddCompany()}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseAddModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <h2 id="admin-h2">Companies Dashboard</h2>
        </Row>
        {companySuccessMsg ?
          <>
            <br />
            <Row>
              <div className="alert alert-success" role="alert">{companySuccessMsg}</div>
            </Row>
          </>
          : <></>}
        <br />
        <Row>
          <Container>
            {adminData.companies.length > 0 ?
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Contact Email</th>
                    <th>Contact Phone</th>
                    <th>Lead Types</th>
                  </tr>
                </thead>
                <tbody>
                  {adminData.companies.map((company, i) =>
                    <tr key={i+company.name+company.contactEmail+company.contactPhone+company._id} onClick={() => { setCurrCompany(company); setCompanySuccessMsg(undefined); }} className="admin-tablerow">
                      <td>{company.name}</td>
                      <td>{company.contactEmail}</td>
                      <td>{company.contactPhone}</td>
                      <td>{company.leadTypes ? company.leadTypes.map((t) => { return t.leadType }).join(", ") : ""}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              : <h4>No companies yet!</h4>}
            {userIsSuperAdmin ? <Button id="admin-btn" onClick={() => { handleShowAddModal(); }}>Add Company</Button> : <></>}
          </Container>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      {currCompany.name !== "" && currCompany.contactEmail !== "" && currCompany.contactPhone !== "" ?
        <CompanyPage company={currCompany} showCompanyPanel={showDefaultCompanyPanel} setSuccessMsg={setCompanySuccessMsg} />
        : defaultCompaniesPanel()
      }
    </Container>
  );
}
