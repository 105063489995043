import { Nav, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn, faRightFromBracket, faPaperPlane, faAddressBook, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export interface IProps {
  isOpen: boolean;
  toggle: any;
  email: string;
  adminType: string;
  setContent: any;
  panelContent: string;
}

export default function Sidebar({ isOpen, toggle, email, adminType, setContent, panelContent }: IProps) {
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <Button
          variant="link"
          onClick={toggle}
          style={{ color: "#fff" }}
          className="mt-4"
        >
          <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
        </Button>
        <h3>Cashifi</h3>
      </div>

      <Nav className="flex-column pt-2">
        <p className="ml-3"><b>{email}</b><br/>{adminType}</p>

        <Nav.Item className={panelContent === "analytics" ? "active" : ""}>
          <Nav.Link onClick={() => setContent("analytics")}>
            <FontAwesomeIcon icon={faChartColumn} className="mr-2" /> Analytics
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className={panelContent === "leads" ? "active" : ""}>
          <Nav.Link onClick={() => setContent("leads")}>
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" /> Leads
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className={panelContent === "companies" ? "active" : ""}>
          <Nav.Link onClick={() => setContent("companies")}>
            <FontAwesomeIcon icon={faAddressBook} className="mr-2" /> Companies
          </Nav.Link>
        </Nav.Item>

      {adminType === "System Admin" ? 
        <Nav.Item className={panelContent === "users" ? "active" : ""}>
          <Nav.Link onClick={() => setContent("users")}>
            <FontAwesomeIcon icon={faUsers} className="mr-2" /> Users
          </Nav.Link>
        </Nav.Item>
      : <></>}

        <br/>

        <Nav.Item className="logout-item">
          <Nav.Link href="/logout">
            <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" /> Logout
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}