import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Container, Row } from "react-bootstrap";
import { attemptLogin } from "../store/thunks/auth";
import { Error } from "../components";
import { Credentials } from "src/store/actions/user";
import { useAppDispatch } from "../store/hooks";
import { useServerError } from "../hooks/useServerError";

type LoginFormValues = Credentials;

export default function LoginPage() {
  const { serverError, handleServerError } = useServerError();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialValues: LoginFormValues = {
    email: "",
    password: ""
  };

  const validationSchema = Yup.object({
    email: Yup.string().min(5).max(255).email().required("Required"),
    password: Yup.string().min(5).max(255).required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values: LoginFormValues) => {
    dispatch(attemptLogin(values))
      .then(() => navigate("/admin"))
      .catch(handleServerError)
      .catch(() => navigate("/login"));
  };

  return (
    <Container className="text-center">
      <Row>
        <h2 id="admin-h2">Admin Portal Login</h2>
      </Row>
      <Row>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <div className='field'>
            <label htmlFor='email'>Email</label><br />
            <input {...register("email")} id='email' type='text' placeholder='Email' />
            {errors.email && <Error>{errors.email.message}</Error>}
          </div>
          <div className='field'>
            <label htmlFor='password'>Password</label><br />
            <input {...register("password")} id='password' type='password' placeholder='Password' />
            {errors.password && <Error>{errors.password.message}</Error>}
          </div>
          <div>
            <Link id="admin-link" to='/login/forgot'>Forgot your password?</Link>
          </div>

          <Button type="submit" id="admin-btn">Login</Button>
          {serverError && <Error>{serverError}</Error>}
        </form>
        {/* <Link to='/register'>Sign Up</Link> */}
      </Row>
    </Container>
  );
}