import { Lead } from "./lead";
import { Company } from "./company";
import { UserInfo } from "./user";

export const SET_LEADS = "SET_LEADS";
export const RESET_LEADS = "RESET_LEADS";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANY = "SET_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const RESET_COMPANIES = "RESET_COMPANIES";
export const SET_USERS = "SET_USERS";
export const RESET_USERS = "RESET_USERS";

export type Admin = {
  leads: Array<Lead>;
  companies: Array<Company>;
  users: Array<UserInfo>
};

export type AdminAction<T> = {
  type: string;
  payload?: T;
};

export function setLeads(leads: Array<Lead>): AdminAction<any> {
  return {
    type: SET_LEADS,
    payload: leads,
  };
}

export function resetLeads(): AdminAction<never> {
  return { type: RESET_LEADS };
}

export function setCompanies(companies: Array<Company>): AdminAction<any> {
  return {
    type: SET_COMPANIES,
    payload: companies,
  };
}

export function setCompany(company: Company): AdminAction<any> {
  return {
    type: SET_COMPANY,
    payload: company,
  };
}

export function addCompany(company: Company): AdminAction<any> {
  return {
    type: ADD_COMPANY,
    payload: company,
  };
}

export function resetCompanies(): AdminAction<never> {
  return { type: RESET_COMPANIES };
}

export function setUsers(users: Array<UserInfo>): AdminAction<any> {
  return {
    type: SET_USERS,
    payload: users,
  };
}

export function resetUsers(): AdminAction<never> {
  return { type: RESET_USERS };
}
