import { Dispatch } from "redux";
import { getUser, updateUserInfo } from "../../api/index";
import { setUser, resetUser, UserInfo } from "../actions/user";

export const attemptGetUser = () => (dispatch: Dispatch) =>
  getUser()
    .then((response) => {
      if (response.data.user) {
        dispatch(setUser(response.data.user));
      } else {
        dispatch(resetUser());
      }
    })
    .catch(() => {
      dispatch(resetUser());
    });

export const attemptUpdateUserInfo = (updatedUser: UserInfo) => () => updateUserInfo(updatedUser);
