import { Modal, Table, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Company } from "src/store/actions/company";
import { useState } from "react";
import { attemptGetCompany, attemptUpdateCompanyInfo } from "src/store/thunks/company";
import { useAppDispatch } from "src/store/hooks";

export interface IProps {
  company: Company;
  isSuperAdmin: boolean;
}

export default function CompanyInfoTable({ company, isSuperAdmin }: IProps) {
  const dispatch = useAppDispatch();
  const [success, setSuccess] = useState<boolean>(false);
  const [actionError, setActionError] = useState<string>();
  const [newCompanyInfo, setNewCompanyInfo] = useState<Company>({ name: company.name, contactEmail: company.contactEmail, contactPhone: company.contactPhone });
  const handleCancelEdit = () => {setNewCompanyInfo(company); setSuccess(false);}
  // Edit company name modal
  const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false);
  const handleCloseEditNameModal = () => {setShowEditNameModal(false); setActionError(undefined);};
  const handleShowEditNameModal = () => {setActionError(undefined); setSuccess(false); setShowEditNameModal(true);}
  // Edit company email modal
  const [showEditEmailModal, setShowEditEmailModal] = useState<boolean>(false);
  const handleCloseEditEmailModal = () => {setShowEditEmailModal(false); setActionError(undefined);};
  const handleShowEditEmailModal = () => {setActionError(undefined); setSuccess(false); setShowEditEmailModal(true);}
  // Edit company phone modal
  const [showEditPhoneModal, setShowEditPhoneModal] = useState<boolean>(false);
  const handleCloseEditPhoneModal = () => { setShowEditPhoneModal(false); setActionError(undefined);};
  const handleShowEditPhoneModal = () => {setActionError(undefined); setSuccess(false); setShowEditPhoneModal(true);}

  const handleSaveNameEdit = async () => {
    if (newCompanyInfo.name !== "") {
      setActionError(undefined);
      let updatedCompany: Company = newCompanyInfo;
      if (company._id) {
        await dispatch(attemptUpdateCompanyInfo(updatedCompany, company._id))
        .then(async () => { if (company._id) await dispatch(attemptGetCompany(company._id)); })
        .then(() => { setSuccess(true); handleCloseEditNameModal(); })
        .catch(() => { setActionError("Error updating company email. Please contact Cashifi if this problem persists."); });
      }
    } else {
      setActionError("Please enter a valid name.");
    }
  };

  const handleSaveEmailEdit = async () => {
    if (newCompanyInfo.contactEmail !== "") {
      setActionError(undefined);
      let updatedCompany: Company = newCompanyInfo;
      if (company._id) {
        await dispatch(attemptUpdateCompanyInfo(updatedCompany, company._id))
        .then(async () => { if (company._id) await dispatch(attemptGetCompany(company._id)); })
        .then(() => { setSuccess(true); handleCloseEditEmailModal(); })
        .catch(() => { setActionError("Error updating company email. Please contact Cashifi if this problem persists."); });
      }
    } else {
      setActionError("Please enter a valid email.");
    }
  };

  const handleSavePhoneEdit = async () => {
    const phoneRegex = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (newCompanyInfo.contactPhone !== "" && phoneRegex.test(newCompanyInfo.contactPhone)) {
      setActionError(undefined);
      let updatedCompany: Company = newCompanyInfo;
      if (company._id) {
        await dispatch(attemptUpdateCompanyInfo(updatedCompany, company._id))
          .then(async () => { if (company._id) await dispatch(attemptGetCompany(company._id)); })
          .then(() => { setSuccess(true); handleCloseEditPhoneModal(); })
          .catch(() => { setActionError("Error updating company phone. Please contact Cashifi if this problem persists."); });
      }
    } else {
      setActionError("Please enter a valid phone number.");
    }
  };

  return (
    <>
      {success ? <div className="alert alert-success" role="alert">
        Successful update! You may need to refresh the page to see changes.</div>
        : <></>}
      <Table responsive bordered>
        <Modal show={showEditNameModal} onHide={() => { handleCancelEdit(); handleCloseEditNameModal(); }}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Company Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {actionError ? <div className="alert alert-danger" role="alert">{actionError}</div> : <></>}
            <Table responsive>
              <tbody>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">Company Name:</Form.Label></td>
                  <td>
                    <Form.Control placeholder="Company name" value={newCompanyInfo.name}
                      onChange={(value) => setNewCompanyInfo((prevState) => ({ ...prevState, name: value.target.value }))} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={async () => { await handleSaveNameEdit(); }}>
              Save
            </Button>
            <Button variant="secondary" onClick={() => {setSuccess(false); handleCloseEditNameModal();}}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showEditEmailModal} onHide={() => { handleCancelEdit(); handleCloseEditEmailModal(); }}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Company Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {actionError ? <div className="alert alert-danger" role="alert">{actionError}</div> : <></>}
            <Table responsive>
              <tbody>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">Company Email:</Form.Label></td>
                  <td>
                    <Form.Control placeholder="Company email" value={newCompanyInfo.contactEmail}
                      onChange={(value) => setNewCompanyInfo((prevState) => ({ ...prevState, contactEmail: value.target.value }))} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={async () => { await handleSaveEmailEdit(); }}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseEditEmailModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showEditPhoneModal} onHide={() => { handleCancelEdit(); handleCloseEditPhoneModal(); }}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Company Phone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {actionError ? <div className="alert alert-danger" role="alert">{actionError}</div> : <></>}
            <Table responsive>
              <tbody>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">Company Phone:</Form.Label></td>
                  <td>
                    <Form.Control placeholder="Company phone" value={newCompanyInfo.contactPhone}
                      onChange={(value) => setNewCompanyInfo((prevState) => ({ ...prevState, contactPhone: value.target.value }))} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={async () => { await handleSavePhoneEdit(); }}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseEditPhoneModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <tbody>
          <tr>
            <td><b>Company Name</b></td>
            <td>{company.name} {isSuperAdmin ? <FontAwesomeIcon icon={faEdit} className="admin-link" onClick={handleShowEditNameModal} /> : <></>}</td>
          </tr>
          <tr>
            <td><b>Contact Email</b></td>
            <td>{company.contactEmail} {isSuperAdmin ? <FontAwesomeIcon icon={faEdit} className="admin-link" onClick={handleShowEditEmailModal} /> : <></>}</td>
          </tr>
          <tr>
            <td><b>Contact Phone</b></td>
            <td>{company.contactPhone} {isSuperAdmin ? <FontAwesomeIcon icon={faEdit} className="admin-link" onClick={handleShowEditPhoneModal} /> : <></>}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}