import { FormEvent } from "react";
import { Form, Card } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';

export interface IProps {
  questions: Array<string>;
  controlTypes: Array<string>;
  options: Array<Array<any>>;
  requireds: Array<boolean>;
  handleFormData: any;
  handleKeyPress: any;
  questionNum: number;
  values: any;
}

export default function TwoStep({
  questions, controlTypes, options, requireds,
  handleFormData, handleKeyPress, questionNum, values
}: IProps) {
  const submitFormData = (e: FormEvent) => {
    e.preventDefault();
  };

  const innerSteps = [0, 1];

  return (
    <div>
      <Card id="form-card">
        <Card.Body className="container-left">
          <Form onSubmit={submitFormData} autoComplete="off">
            {innerSteps.map((innerStep, i) =>

              <Form.Group key={i}>
                <Form.Label>{questions[i]}{requireds[i] ? <span className="color-req"> *</span> : <></>}</Form.Label>

                {controlTypes[i] === "name" ?
                  <Form.Control
                    id="form-step"
                    className="shadow-none"
                    name={String(questionNum)}
                    defaultValue={values[questionNum][i]}
                    type={controlTypes[i]}
                    placeholder={values[questionNum][i]}
                    onChange={handleFormData(questionNum, "control", i)}
                    onKeyDown={handleKeyPress}
                  />
                  : <></>}

                {controlTypes[i] === "text" || controlTypes[i] === "number" || controlTypes[i] === "email" ?
                  <Form.Control
                    id="form-step"
                    className="shadow-none"
                    name={String(questionNum)}
                    defaultValue={values[questionNum][i]}
                    type={controlTypes[i]}
                    placeholder={values[questionNum][i]}
                    onChange={handleFormData(questionNum, "control", i)}
                    onKeyDown={handleKeyPress}
                  />
                  : <></>}

                {controlTypes[i] === "select" ?
                  <Form.Control
                    id="form-step"
                    className="shadow-none"
                    as="select"
                    name={String(questionNum)}
                    value={values[questionNum][i]}
                    onChange={handleFormData(questionNum, "control", i)}
                    onKeyDown={handleKeyPress}
                  >
                    {options.map((opt, j) => <option key={j} value={opt}>{opt}</option>)}
                  </Form.Control>
                  : <></>}

                {controlTypes[i] === "radio" ?
                  <Form.Group
                    onKeyDown={handleKeyPress}
                  >
                    {options.map((opt, j) => <Form.Check
                      className="shadow-none"
                      checked={values[questionNum] === opt}
                      type="radio" key={j}
                      value={opt}
                      label={opt}
                      onChange={handleFormData(questionNum, "control", i)} />)
                    }
                  </Form.Group>
                  : <></>}

                {controlTypes[i] === "date" ?
                  <Form.Control
                    id="form-step"
                    className="shadow-none"
                    name={String(questionNum)}
                    defaultValue={values[questionNum][i]}
                    type={controlTypes[i]}
                    placeholder={values[questionNum][i]}
                    onChange={handleFormData(questionNum, "control", i)}
                    onKeyDown={handleKeyPress}
                  />
                  : <></>}

                {controlTypes[i] === "currency" ?
                  <div>
                    <CurrencyInput
                      id="form-step"
                      name={String(questionNum)}
                      placeholder={values[questionNum][i]}
                      defaultValue={values[questionNum][i]}
                      decimalsLimit={2}
                      allowNegativeValue={false}
                      prefix={"$"}
                      onValueChange={handleFormData(questionNum, "currency", i)}
                      onKeyDown={handleKeyPress}
                    />
                  </div>
                  : <></>}
                  <br/>
              </Form.Group>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}