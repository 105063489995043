import { FormEvent } from "react";
import { Form, Card } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';

export interface IProps {
  question: string;
  questionNum: number;
  controlType: string;
  options: Array<any>;
  handleFormData: any;
  handleKeyPress: any;
  values: any;
  required: boolean;
}

export default function Step({ question, questionNum, controlType, options, handleFormData, handleKeyPress, values, required }: IProps) {
  const submitFormData = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <div>
      <Card id="form-card">
        <Card.Body className="container-left">
          <Form onSubmit={submitFormData} autoComplete="off">
            <Form.Group>
              <Form.Label>{question}{required ? <span className="color-req"> *</span> : <></>}</Form.Label>

              {controlType === "name" ?
                <Form.Control
                  id="form-step"
                  className="shadow-none"
                  name={String(questionNum)}
                  defaultValue={values[questionNum]}
                  type={controlType}
                  placeholder={values[questionNum]}
                  onChange={handleFormData(questionNum, "control")}
                  onKeyDown={handleKeyPress}
                />
                : <></>}

              {controlType === "text" || controlType === "number" || controlType === "email" ?
                <Form.Control
                  id="form-step"
                  className="shadow-none"
                  name={String(questionNum)}
                  defaultValue={values[questionNum]}
                  type={controlType}
                  placeholder={values[questionNum]}
                  onChange={handleFormData(questionNum, "control")}
                  onKeyDown={handleKeyPress}
                />
                : <></>}

              {controlType === "select" ?
                <Form.Control
                  id="form-step"
                  className="shadow-none"
                  as={controlType}
                  name={String(questionNum)}
                  value={values[questionNum]}
                  onChange={handleFormData(questionNum, "control")}
                  onKeyDown={handleKeyPress}
                >
                  {options.map((opt, i) => <option key={i} value={opt}>{opt}</option>)}
                </Form.Control>
                : <></>}

              {controlType === "radio" ?
                <Form.Group
                  onKeyDown={handleKeyPress}
                >
                  {options.map((opt, i) => <Form.Check
                    className="shadow-none"
                    checked={values[questionNum] === opt}
                    type={controlType} key={i}
                    value={opt}
                    label={opt}
                    onChange={handleFormData(questionNum, "control")} />)
                    }
                </Form.Group>
                : <></>}

              {controlType === "date" ?
                <Form.Control
                  id="form-step"
                  className="shadow-none"
                  name={String(questionNum)}
                  defaultValue={values[questionNum]}
                  type={controlType}
                  placeholder={values[questionNum]}
                  onChange={handleFormData(questionNum, "control")}
                  onKeyDown={handleKeyPress}
                />
                : <></>}

              {controlType === "currency" ?
                <div>
                  <CurrencyInput
                    id="form-step"
                    name={String(questionNum)}
                    placeholder={values[questionNum]}
                    defaultValue={values[questionNum]}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    prefix={"$"}
                    onValueChange={handleFormData(questionNum, "currency")}
                    onKeyDown={handleKeyPress}
                  />
                </div>
              : <></>}

            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}