import { Container } from "react-bootstrap";
import classNames from "classnames";
import AdminNavbar from "./AdminNavbar";
import AnalyticsPanel from "./Panels/AnalyticsPanel";
import LeadsPanel from "./Panels/LeadsPanel";
import CompaniesPanel from "./Panels/Companies/CompaniesPanel";
import UsersPanel from "./Panels/UsersPanel";
import "./ContentPanel.css";

export interface IProps {
  isOpen: boolean;
  toggle: any;
  panelContent: string;
}

export default function ContentPanel({ isOpen, toggle, panelContent }: IProps) {
  return (
    <Container
      fluid
      className={classNames("content", { "is-open": isOpen })}>
      <AdminNavbar toggle={toggle} />
      {panelContent === "analytics" ? <AnalyticsPanel/> : <></>}
      {panelContent === "leads" ? <LeadsPanel/> : <></>}
      {panelContent === "companies" ? <CompaniesPanel/> : <></>}
      {panelContent === "users" ? <UsersPanel/> : <></>}
    </Container>
  );
}