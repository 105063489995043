import { createStore, applyMiddleware, compose, Store, Middleware } from "redux";
import { createLogger } from "redux-logger";

import thunk from "redux-thunk";
import buildRootReducer from "./reducers/index";
import { UserState } from "./reducers/user";
import { UserAction } from "./actions/user";
import { AdminState } from "./reducers/admin";
import { AdminAction } from "./actions/admin";

import { persistStore, persistReducer, Persistor } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistPartial } from "redux-persist/es/persistReducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

type AppState = { user: UserState, admin: AdminState };

const initialState: AppState = {
  user: {
    isAuth: false,
    isAdmin: false,
    isSuperAdmin: false,
    isSystemAdmin: false,
    user: null,
  },
  admin: {
    leads: [],
    companies: [],
    users: []
  }
};

const persistConfig = {
  key: 'root',
  storage,
}

interface configureStoreType {
  store: Store<AppState & PersistPartial, UserAction<any> | AdminAction<any>>;
  persistor: Persistor;
}

export default function configureStore(
  state: AppState = initialState
): configureStoreType {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewares: Array<Middleware> = [thunk];

  if (process.env.NODE_ENV !== "production") {
    const logger = createLogger({ collapsed: true, diff: true });
    middlewares.push(logger);
  }

  const rootReducer = buildRootReducer();
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  let store = createStore(persistedReducer, state, composeEnhancers(applyMiddleware(...middlewares)));
  let persistor = persistStore(store);
  return { store, persistor };
}