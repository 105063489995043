import { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, ProgressBar } from "react-bootstrap";
import { attemptSubmitLead } from "../../store/thunks/lead";
import { useAppDispatch } from "../../store/hooks";
import { Lead } from "../../store/actions/lead";
import Step from "./FormComponents/Step";
import TwoStep from "./FormComponents/TwoStep";
import dayjs from "dayjs";
import "./FormParent.css";
import { leadExists } from "../../api";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";

export default function BusinessApplyFormParent() {
  let navigate = useNavigate();
  const NUM_STEPS = 14;
  const [step, setStep] = useState(0);
  const defaultFormData = {
    0: "Select your Business Type", // Business type
    1: "Select a Loan Amount", // Loan amount
    2: "Select your Funding Purpose", // Purpose of funding
    3: "mm/dd/yyyy",  // Business start date
    4: "Select your Annual Revenue", // Annual revenue
    5: "", // Credit score
    6: "", // Business name
    7: "Select your Business Industry", // Industry
    8: 0, // Deposits last month
    9: "", // Business zip code
    10: {0: "", 1: ""}, // First name, Last name
    11: "", // Phone number
    12: "", // Email
    13: "mm/dd/yyyy" // When would you like to receive funding?
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setFormError] = useState(false);
  const [success, setFormSuccess] = useState(false);
  const [errorMsg, setFormErrorMsg] = useState("");
  const [analyticsGLQuery, setAnalyticsGLQuery] = useState("");
  const [analyticsGAQuery, setAnalyticsGAQuery] = useState("");
  const dispatch = useAppDispatch();
  const query = useQuery();
  const captchaRef = useRef<any>(null);

  useEffect(() => {
    const glQueryString = query.get("_gl");
    const gaQueryString = query.get("_ga");
    if(glQueryString)
      setAnalyticsGLQuery(glQueryString);
    if(gaQueryString)
      setAnalyticsGAQuery(gaQueryString);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async () => {
    if (await validateInput(step)) {
      setFormError(false);
      setFormErrorMsg("");
      // Create application
      const newLead: Lead = {
        email: formData[12], 
        firstName: formData[10][0],
        lastName: formData[10][1],
        phoneNumber: formData[11],
        businessType: formData[0],
        loanAmount: formData[1],
        fundingPurpose: formData[2],
        businessStartDate: dayjs(formData[3]).toDate(),
        annualRevenue: formData[4],
        creditScore: formData[5],
        businessName: formData[6],
        industry: formData[7],
        lastMonthDeposit: Number(formData[8]).valueOf(),
        businessZipCode: formData[9],
        fundingTime: dayjs(formData[13]).toDate(),
        analyticsGLQuery: analyticsGLQuery,
        analyticsGAQuery: analyticsGAQuery,
        sourceUrl: "https://apply.cashifi.com",
        frontendId: "v1-react-frontend",
        userIp: "",
        address: "",
      };
      if(captchaRef.current) {
        let token;
        token = captchaRef.current.getValue();
        if(token === null)
          token = "";
        // console.log(token);
        captchaRef.current.reset();
        dispatch(attemptSubmitLead(newLead, token))
          .then(() => {incrementStep(); setFormSuccess(true); navigate("/apply/success"); })
          .catch((err) => { 
            setFormError(true); 
            if(err.response.status === 401)
              setFormErrorMsg("Error with reCAPTCHA. Please try again.") 
            else
              setFormErrorMsg("Error submitting application. Please contact Cashifi if this problem persists.") 
          });
      } else {
        setFormSuccess(false);
        setFormError(true);
        setFormErrorMsg("Error with reCAPTCHA. Please try again.");
      }
    } else {
      setFormSuccess(false);
      setFormError(true);
      // setFormErrorMsg("This field is required.");
    }
  };

  const incrementStep = () => {
    if(step < NUM_STEPS)
      setStep(step + 1);
  }

  const decrementStep = () => {
    if(step > 0)
      setStep(step - 1);
  }

  const nextStep = async () => {
    if (await validateInput(step)) {
      setFormError(false);
      setFormErrorMsg("");
      incrementStep();
    } else {
      setFormSuccess(false);
    }
  };

  const prevStep = () => {
    decrementStep();
  };

  const validateInput = async (currStep: number): Promise<boolean> => {
    if (currStep === 10) { // Name/string twostep
      if (formData[step][0] === "" || formData[step][1] === "") {
        setFormError(true);
        setFormErrorMsg("Please enter valid text.");
        return false;
      }
    } else if (currStep === 9) { // Zip code
      const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
      if (!zipRegex.test(formData[step])) {
        setFormError(true);
        setFormErrorMsg("Invalid zip code.");
        return false;
      }
    } else if (currStep === 6 || currStep === 9) {
      if (formData[step] === "") {
        setFormError(true);
        setFormErrorMsg("Please enter valid text.");
        return false;
      }
    } else if (currStep === 12) { // Email
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(formData[step])) {
        setFormError(true);
        setFormErrorMsg("Invalid email.");
        return false;
      } else {
        const response = await leadExists(formData[step]);
        if (response.data.exists) {
          setFormError(true);
          setFormErrorMsg("Email already in use.");
          return false;
        }
      }
    } else if (currStep === 8) { // Currency
      if (formData[step] <= 0) {
        setFormError(true);
        setFormErrorMsg("Please enter an amount greater than zero.");
        return false;
      }
    } else if (currStep === 0 || currStep === 1 || currStep === 2 || currStep === 4 || currStep === 5 || currStep === 7) { // Radio options Or Dropdown selection
      if (formData[step] === "" || formData[step] === defaultFormData[currStep]) {
        setFormError(true);
        setFormErrorMsg("Please select an option.");
        return false;
      }
    } else if (currStep === 3) { // Past Date
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(formData[step]) || dayjs().isBefore(dayjs(formData[step], "YYYY-MM-DD"))) {
        setFormError(true);
        setFormErrorMsg("Please enter a valid date.");
        return false;
      }
    } else if (currStep === 13) { // Future Date
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(formData[step]) || dayjs().isAfter(dayjs(formData[step], "YYYY-MM-DD"))) {
        setFormError(true);
        setFormErrorMsg("Please enter a valid date.");
        return false;
      }
    }
    else if (currStep === 11) { // Phone number
      // const phoneNumberRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      const phoneNumberRegex = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      if (!phoneNumberRegex.test(formData[step])) {
        setFormError(true);
        setFormErrorMsg("Please enter a valid phone number.");
        return false;
      }
    }

    return true;
  }

  const handleInputDataMultiStep = (input: string, inputType: string, inputStep: number) => (e: React.FormEvent<HTMLInputElement>) => {
    let value: any;
    if (inputType === "control") {
      var element = e.target as HTMLInputElement;
      value = element.value.trim();
    } else if (inputType === "currency") {
      value = e;
    }

    const newData = {...formData[input]}
    newData[inputStep] = value;
    setFormData(prevState => ({
      ...prevState,
      [input]: newData
    }));
  }

  const handleInputData = (input: string, inputType: string) => (e: React.FormEvent<HTMLInputElement>) => {
    let value: any;
    if (inputType === "control") {
      var element = e.target as HTMLInputElement;
      value = element.value.trim();
    } else if (inputType === "currency") {
      value = e;
    }

    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      if (step === NUM_STEPS - 1)
        submitForm();
      else
        nextStep();
    }
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          {/* {isMobile ? 
            <ProgressBar now={step} max={NUM_STEPS} label={`${Math.floor((step/NUM_STEPS)*100)}%`} /> :
          <Stepper step={step}>
            {Array(NUM_STEPS).fill(0).map((val, i) => <PStep key={i}></PStep>)}
          </Stepper>
          } */}
          <ProgressBar now={step} max={NUM_STEPS} label={`${Math.floor((step/NUM_STEPS)*100)}%`} />
        </Col>
      </Row>
      <Row className="pd-t">
        <Col md={12}>
          {error ? <div className="alert alert-danger" role="alert">{errorMsg}</div> : <></>}
          {success ? <div className="alert alert-success" role="alert">
            Successful submission!<br />
            If you qualify to receive funding for any business loan, a partner will be reaching out to you.</div>
            : <></>}
        </Col>
      </Row>
      {!success ?
        <Row>
          <Col md={12}>
            {step === 0 ?
              <Step
                question="What is your business type?"
                questionNum={0}
                controlType="select"
                options={["Select your Business Type", "Sole Proprietorship", "Partnership", "Limited Liability Company LLC", "C Corporation", "S Corporation"]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 1 ?
              <Step
                question="How much money do you need?"
                questionNum={1}
                controlType="select"
                options={["Select a Loan Amount", "$10k-$30k", "$30k-75k", "$75k-150k", "$150k-250k", "$250k+"]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 2 ?
              <Step
                question="What are you seeking funding for?"
                questionNum={2}
                controlType="select"
                options={["Select your Funding Purpose", "Expansion", "Equipment Purchase", "Finance Accounts Receivable",
                  "Inventory", "Marketing", "Payroll", "Purchase Real Estate", "Remodel ", "Consolidate Debt",
                  "Working Capital / Cash Flow", "Other"]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 3 ?
              <Step
                question="When did you open your business?"
                questionNum={3}
                controlType="date"
                options={[]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 4 ?
              <Step
                question="What is your annual revenue?"
                questionNum={4}
                controlType="select"
                options={["Select your Annual Revenue", "$10k-$50k", "$50k-$100k", "$100k-$250k", "$250k-$500k", "$500k-$1m", "$1m+"]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 5 ?
              <Step
                question="What is your personal credit score?"
                questionNum={5}
                controlType="radio"
                options={["Poor (639 or less)", "Fair (640-679)", "Good (680-719)", "Excellent (720+)"]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 6 ?
              <Step
                question="What is the name of your business?"
                questionNum={6}
                controlType="name"
                options={[]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 7 ?
              <Step
                question={"What industry is your business?"}
                questionNum={7}
                controlType="select"
                options={["Select your Business Industry", "Accommodation and Food Services",
                  "Administrative & Support and Waste Management & Remediation Services",
                  "Agriculture, Forestry, Fishing, and Hunting", "Arts, Entertainment, and Recreation",
                  "Construction", "Educational Services", "Finance and Insurance",
                  "Health Care and Social Assistance", "Information",
                  "Management of Companies and Enterprises", "Manufacturing", "Mining",
                  "Other Services", "Professional, Scientific, and Technical Services",
                  "Public Administration", "Real Estate", "Retail Trade", "Transportation",
                  "Utilities", "Wholesale Trade", "Other"]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 8 ?
              <Step
                question="How much money did you deposit last month?"
                questionNum={8}
                controlType="currency"
                options={[]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 9 ?
              <Step
                question="What is your business zip code?"
                questionNum={9}
                controlType="text"
                options={[]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 10 ?
              <TwoStep
                questions={["What is your first name?", "What is your last name?"]}
                controlTypes={["name", "name"]}
                options={[[], []]}
                requireds={[true, true]}
                handleFormData={handleInputDataMultiStep}
                handleKeyPress={handleKeyPress}
                questionNum={10}
                values={formData}
              /> : <></>}
            {step === 11 ?
              <Step
                question="What is the best phone number to reach you at?"
                questionNum={11}
                controlType="text"
                options={[]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 12 ?
              <Step
                question="What is the best email to reach you at?"
                questionNum={12}
                controlType="email"
                options={[]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
            {step === 13 ?
              <Step
                question="When would you like to receive funding?"
                questionNum={13}
                controlType="date"
                options={[]}
                handleFormData={handleInputData}
                handleKeyPress={handleKeyPress}
                values={formData}
                required={true}
              /> : <></>}
          </Col>
        </Row>
        : <></>}
      {!success ?
        <Row className="pd-t">
          <Col>
            <div id="group-center">
              {step === NUM_STEPS - 1 && process.env.REACT_APP_RECAPTCHA_SITE_KEY ? <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} /> : <></>}
              <br/>
            </div>
            {step > 0 ? <Button className="shadow-none" id="form-btn" onClick={prevStep}>Previous</Button> : <></>}
            {step < NUM_STEPS - 1 ? <Button className="shadow-none" id="form-btn" onClick={nextStep}>Next</Button> : <></>}
            {step === NUM_STEPS - 1 ? <Button className="shadow-none" id="form-btn" onClick={submitForm}>Submit</Button> : <></>}
          </Col>
        </Row>
        : <></>}
      {step === 11 ? 
        <Row className="pd-t">
          <Col>
            <p id="text-small">By clicking on the "Next" button above, I consent, acknowledge, and agree to the following:</p>
            <ul>
              <li id="text-small" className="text-left">Our Terms of Use and Privacy Policy and to receive important notices, and other communications electronically.</li>
              <li id="text-small" className="text-left">You are providing express written consent to share your information with up to five (5) network partners, and for Cashifi.com, parties calling on behalf of Cashifi.com, network partners, or an authorized third party on their behalf to call you (including through automated means; e.g. autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS - charges may apply) and/or email, even if your telephone number is currently listed on any internal, corporate, state, federal or national Do-Not-Call (DNC) list.</li>
              <li id="text-small" className="text-left">Permission is not required as a condition to utilize Cashifi.com services, and you may choose to be contacted by an individual customer care representative by calling (Phone Number)</li>
              <li id="text-small" className="text-left">Cashifi.com network partners will contact you directly to consider your loan options as well as to obtain additional financial information in relationship to your offer of credit you receive from them.</li>
              <li id="text-small" className="text-left">I am instructing Cashifi.com to share my information to the providers in its network for the intent of providing me with information about their financial services and products.</li>
            </ul>
          </Col>
      </Row> : <></>}
      {step === 12 ? 
        <Row className="pd-t">
          <Col>
            <p id="text-small">You recognize that by clicking on the "Next" button above that you are providing written instructions to Cashifi.com under the Fair Credit Reporting Act authorizing Cashifi.com to acquire information from your personal credit profile or other information from a national credit reporting agency. You accept by clicking the Continue button that you are a business owner and are personally liable for this business transaction. You authorize Cashifi.com to obtain such information for the purposes of matching you with our network partners.</p>
          </Col>
      </Row> : <></>}
    </Container>
  );
}