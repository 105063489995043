import { useEffect, useState } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import { Lead } from "../../../../store/actions/lead";
import { useAppSelector } from "../../../../store/hooks";
import dayjs from "dayjs";

export default function AnalyticsPanel() {
  const adminData = useAppSelector((state) => state.admin);
  const [leadsDay, setLeadsDay] = useState<number>(0);
  const [leadsWeek, setLeadsWeek] = useState<number>(0);
  const [leadsMonth, setLeadsMonth] = useState<number>(0);

  useEffect(() => { 
    const filterLeads = (leadsToFilter: Array<Lead>, filterType: string) => {
      let leads: Array<Lead>;
      if (filterType === "day")
        leads = leadsToFilter.filter((lead) => dayjs().isSame(lead.submissionDate, "day"));
      else if (filterType === "week")
        leads = leadsToFilter.filter((lead) => dayjs().isSame(lead.submissionDate, "week"));
      else if (filterType === "month")
        leads = leadsToFilter.filter((lead) => dayjs().isSame(lead.submissionDate, "month"));
      else
        leads = leadsToFilter;
      return leads;
    };

    setLeadsMonth(filterLeads(adminData.leads, "month").length);
    setLeadsWeek(filterLeads(adminData.leads, "week").length);
    setLeadsDay(filterLeads(adminData.leads, "day").length);

  }, [adminData.leads]);

  return (
    <Container>
      <Row>
        <h2 id="admin-h2">Analytics Dashboard</h2>
      </Row>
      <br />
      <Row>
        <Col md={4} className="stat-col">
          <Card className="stat-card">
            <Card.Body className="text-center">
              <Card.Text>
                <span className="stat-num" id="admin-h1">{leadsDay}</span>
                <span className="stat-caption">{leadsDay === 1 ? "lead" : "leads"} today</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="stat-col">
          <Card className="stat-card">
            <Card.Body className="text-center">
              <Card.Text className="stat-card">
                <span className="stat-num" id="admin-h1">{leadsWeek}</span>
                <span className="stat-caption">{leadsWeek === 1 ? "lead" : "leads"} this week</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="stat-col">
          <Card className="stat-card">
            <Card.Body className="text-center">
              <Card.Text>
                <span className="stat-num" id="admin-h1">{leadsMonth}</span>
                <span className="stat-caption">{leadsMonth === 1 ? "lead" : "leads"} this month</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="stat-col">
          <Card className="stat-card">
            <Card.Body className="text-center">
              <Card.Text>
                <span className="stat-num" id="admin-h1">{adminData.companies.length}</span>
                <span className="stat-caption">{adminData.companies.length === 1 ? "company" : "companies"} subscribed</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}