import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { attemptLogout } from "../../store/thunks/auth";
import { useAppDispatch } from "src/store/hooks";

export default function LogoutPage() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(attemptLogout());
    navigate("/login");
  }, [dispatch, navigate]);


  return <p>Logout in progress</p>;
}