import { useState } from "react";
import { Container, Row, Modal, Form, Table, Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useServerError } from "src/hooks/useServerError";
import UserInfoRow from "../../../../components/UserInfoRow";
import { Credentials } from "src/store/actions/user";
import { attemptRegister } from "src/store/thunks/auth";
import { Error } from "src/components";
import { attemptGetAllUsersInfos } from "src/store/thunks/admin";

export default function UsersPanel() {
  const dispatch = useAppDispatch();
  const { serverError, handleServerError } = useServerError();
  const adminData = useAppSelector((state) => state.admin);
  const [newUser, setNewUser] = useState<Credentials>({
    email: "",
    password: ""
  });

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [saveNewUserError, setSaveNewUserError] = useState<string>();
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCancelAddUser = () => setNewUser({ email: "", password: "" });
  
  const handleSaveAddUser = async () => {
    if(newUser && newUser.email !== "" && newUser.password !== "") {
      if(newUser.email.length >= 3 && newUser.email.length <= 50) {
        if(newUser.password.length >= 5 && newUser.password.length <= 255) {
          setSaveNewUserError(undefined);
          await dispatch(attemptRegister(newUser))
          .catch(handleServerError);
          await dispatch(attemptGetAllUsersInfos());
          handleCloseAddModal();
        } else {
          setSaveNewUserError("Invalid password. Must be between 5 and 255 characters.")
        }
      } else {
        setSaveNewUserError("Invalid email. Must be between 3 and 50 characters.")
      }
    }
  };

  return (
    <Container>
      <Modal show={showAddModal} onHide={() => { handleCancelAddUser(); handleCloseAddModal(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {saveNewUserError ? <div className="alert alert-danger" role="alert">{saveNewUserError}</div> : <></>}
          <Table responsive>
              <tbody>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">User Email:</Form.Label></td>
                  <td><Form.Control placeholder="User email" value={newUser?.email} onChange={(value) => setNewUser({ email: value.target.value, password: newUser.password })} /></td>
                </tr>
                <tr className="admin-modal-tablerow">
                  <td><Form.Label id="admin-form-label">User Password:</Form.Label></td>
                  <td><Form.Control placeholder="User password" value={newUser?.password} onChange={(value) => setNewUser({ email: newUser.email, password: value.target.value.trim() })} /></td>
                </tr>
              </tbody>
            </Table>
            {serverError && <Error>{serverError}</Error>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={async () => {await handleSaveAddUser();}}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <h2 id="admin-h2">Users Dashboard</h2>
      </Row>
      <br />
      <Row>
        <Container>
          <Row>
            {adminData.users.length > 0 ?
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Verified?</th>
                    <th>Admin Type</th>
                  </tr>
                </thead>
                <tbody>
                  {adminData.users.map((user, i) =>
                    <UserInfoRow userData={user} key={i}/>
                  )}
                </tbody>
              </Table>
              : <>
                <h4>No users yet!</h4>
              </>}
              <Button id="admin-btn" onClick={() => { handleShowAddModal(); }}>Add New User</Button>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}
