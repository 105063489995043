import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import {
  ConfirmPage,
  HomePage,
  AdminPanelPage,
  LoginPage,
  ResetPasswordRequestPage,
  ResetPasswordPage,
  LogoutPage,
  // RegisterPage,
  ApplySuccessPage
} from "./pages";
import { UserProtectedRoute, AdminProtectedRoute, NavBar } from "./components";
import { AuthRoute } from "./components/AuthRoute";

export default function App() {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/admin" ? <></> : <NavBar />}
        <Routes>
          {/* <Route path='/apply' element={<HomePage/>} /> */}
          <Route path='/' element={<HomePage/>} />
          <Route path='/account/confirm/:token' element={<AuthRoute><ConfirmPage/></AuthRoute>}/>
          {/* <Route path='/register' element={<AuthRoute><RegisterPage/></AuthRoute>}/> */}
          <Route path='/login' element={<AuthRoute><LoginPage/></AuthRoute>}/>
          <Route path='/login/forgot' element={<AuthRoute><ResetPasswordRequestPage/></AuthRoute>}/>
          <Route path='/login/reset/:token' element={<AuthRoute><ResetPasswordPage/></AuthRoute>}/>
          <Route path='/logout' element={<UserProtectedRoute><LogoutPage/></UserProtectedRoute>}/>
          <Route path='/admin' element={<AdminProtectedRoute><AdminPanelPage/></AdminProtectedRoute>}/>
          <Route path="/apply" element={<Navigate to="/" />}/>
          <Route path="/apply/success" element={<ApplySuccessPage/>}/>
        </Routes>
    </>
  );
}