import { useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { useAppDispatch } from "../store/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { attemptUpdateUserInfo } from "../store/thunks/user";
import { UserInfo } from "../store/actions/user";

export interface IProps {
  userData: UserInfo;
}

export default function UserInfoRow({ userData }: IProps) {
  const dispatch = useAppDispatch();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [actionError, setActionError] = useState<string>();
  const [newAdminType, setNewAdminType] = useState<string>("");
  const handleCloseEditModal = () => { setShowEditModal(false); setActionError(undefined) };
  const handleShowEditModal = () => setShowEditModal(true);
  const handleCancelEdit = () => setNewAdminType("");

  const handleSaveEdit = async () => {
    if (newAdminType !== "" && userData.email) {
      setActionError(undefined);
      const newAdmin = newAdminType === "admin" || newAdminType === "super admin" || newAdminType === "system admin";
      const newSuperAdmin = newAdminType === "super admin" || newAdminType === "system admin";
      const newSystemAdmin = newAdminType === "system admin";
      let updatedUser: UserInfo = { ...userData, isAdmin: newAdmin, isSuperAdmin: newSuperAdmin, isSystemAdmin: newSystemAdmin };
      await dispatch(attemptUpdateUserInfo(updatedUser)).catch(() => { setActionError("Error updating user. Please contact Cashifi if this problem persists."); });
      // await dispatch(attemptGetUser(companyId));
      handleCloseEditModal();
    } else {
      setActionError("Please select a valid admin type.");
    }
  };

  return (
    <tr>
      <Modal show={showEditModal} onHide={() => { handleCancelEdit(); handleCloseEditModal(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Admin Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {actionError ? <div className="alert alert-danger" role="alert">{actionError}</div> : <></>}
          <Table responsive>
            <tbody>
              <tr className="admin-modal-tablerow">
                <td><Form.Label id="admin-form-label">Admin Type:</Form.Label></td>
                <td>
                  <Form.Select aria-label="Admin type edit" className="admin-type-select" onChange={(value) => setNewAdminType(value.target.value)} defaultValue={userData.isSystemAdmin ? "system admin" : (userData.isSuperAdmin ? "super admin" : (userData.isAdmin ? "admin" : "inactive"))}>
                    <option value="inactive">Inactive</option>
                    <option value="admin">Admin</option>
                    <option value="super admin">Super Admin</option>
                    <option value="system admin">System Admin</option>
                  </Form.Select>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={async () => { await handleSaveEdit(); }}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <td>{userData.email}</td>
      <td>{userData.isVerified ? "Yes" : "No"}</td>
      <td>{userData.isSystemAdmin ? "System Admin" : (userData.isSuperAdmin ? "Super Admin" : (userData.isAdmin ? "Admin" : "Inactive"))} <FontAwesomeIcon icon={faEdit} className="admin-link" onClick={handleShowEditModal} /></td>
    </tr>
  );
}