import { Credentials, User, UserInfo } from "../store/actions/user";
import { Lead } from "../store/actions/lead";
import { Company, CompanyLeadClass } from "../store/actions/company";
import http from "../services/httpService";

const postLogin = (credentials: Credentials) =>
  http.post<{ user: User }>("/auth/login", credentials);

const sendResetPasswordLink = (email: string) => http.post("/auth/login/forgot", { email });

const resetPassword = (password: string, token: string) =>
  http.post<void>(`/auth/login/reset/${token}`, { password });

const postLogout = () => http.post<void>("/auth/logout");

const postUser = (user: User) => http.post<void>("/user/register", user);

const getConfirmation = (token: string) => http.get<void>(`/auth/confirmation/${token}`);

const resendConfirmation = (email: string) => http.post<void>("/auth/send-confirmation", { email });

// const resetRegister = (email: string) => http.post<void>("/user/register/cancel", { email });

const getUser = () => http.get<{ user: User }>("/user");

const getUsersInfos = () => http.get<{ users: Array<UserInfo> }>("/user/users-infos");

const updateUserInfo = (user: UserInfo) => http.put<void>("/user/update-info", user);

// Lead API functions
const postLead = (lead: Lead, token: string) => http.post<void>("/lead/create", {lead: lead, token: token});

const deleteLead = (leadId: string) => http.delete<void>(`/lead/delete/${leadId}`);

const getLeads = () => http.get<{ leads: Array<Lead> }>("/lead/leads");

const leadExists = (email: string) => http.get<{ exists: boolean }>(`/lead/exists/${email}`);

const getLeadsExport = (leadIds: Array<string>) => http.post<{ matchingLeads: Array<Lead> }>("/lead/export", {"leadIds": leadIds});

// Company API functions
const postCompany = (company: Company) => http.post<void>("/company/create", company);

const getCompanies = () => http.get<{ companies: Array<Company> }>("/company/companies");

const getCompany = (companyId: string) => http.get<{ company: Company }>(`/company/${companyId}`);

const deleteCompany = (companyId: string) => http.delete<void>(`/company/delete/${companyId}`);

const updateCompanyInfo = (company: Company, companyId: string) => http.put<void>(`/company/update-info/${companyId}`, company);

const putLeadClass = (companyLeadClass: any) => http.put<void>("/company/add-lead-class", companyLeadClass);

const updateLeadClass = (updatedLeadClass: CompanyLeadClass) => http.put<void>("/company/update-lead-class", updatedLeadClass);

const deleteLeadClass = (companyId: string, leadTypeClass: string) => http.put<void>("/company/delete-lead-class", { companyId, leadTypeClass });

const testWebhook = (webhook: string) => http.post<void>("/company/test-webhook", {"webhook": webhook});

export {
  postLogin,
  sendResetPasswordLink,
  resetPassword,
  postLogout,
  postUser,
  getUsersInfos,
  getConfirmation,
  resendConfirmation,
  getUser,
  updateUserInfo,
  // resetRegister,
  postLead,
  deleteLead,
  getLeads,
  leadExists,
  postCompany,
  getCompanies,
  deleteCompany,
  updateCompanyInfo,
  putLeadClass,
  getCompany,
  updateLeadClass,
  deleteLeadClass,
  testWebhook,
  getLeadsExport
};