import { SET_LEADS, RESET_LEADS, SET_COMPANIES, SET_COMPANY, RESET_COMPANIES, SET_USERS, RESET_USERS, AdminAction } from "../actions/admin";
import { Lead } from "../actions/lead";
import { Company } from "../actions/company";
import { UserInfo } from "../actions/user";

export type AdminState = {
  leads: Array<Lead>;
  companies: Array<Company>;
  users: Array<UserInfo>;
};

const initialState: AdminState = {
  leads: [],
  companies: [],
  users: []
};

export default function admin(state = initialState, action: AdminAction<any>): AdminState {
  switch (action.type) {
    case SET_LEADS:
      return {
        ...state,
        leads: action.payload,
      }
    case RESET_LEADS:
      return {
        ...state,
        leads: initialState.leads,
      }
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.payload
      }
    case SET_COMPANY:
      return {
        ...state,
        companies: [...state.companies.filter(company => company._id !== action.payload._id), action.payload],
      }
    case RESET_COMPANIES:
      return {
        ...state,
        companies: initialState.companies
      }
      case SET_USERS:
        return {
          ...state,
          users: action.payload,
        }
      case RESET_USERS:
        return {
          ...state,
          users: initialState.users,
        }
    default:
      return state;
  }
}