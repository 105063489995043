import { Dispatch } from "redux";
import { getLeads, getCompanies, getUsersInfos } from "../../api/index";
import { setLeads, resetLeads, setCompanies, resetCompanies, setUsers, resetUsers } from "../actions/admin";

export const attemptGetAllLeads = () => (dispatch: Dispatch) =>
  getLeads()
    .then((response) => {
      // console.log(response.data);
      if (response.data.leads) {
        dispatch(setLeads(response.data.leads));
      } else {
        console.log("Error fetching leads");
        dispatch(resetLeads());
      }
    })
    .catch(() => {
      dispatch(resetLeads());
    });

export const attemptGetAllCompanies = () => (dispatch: Dispatch) =>
  getCompanies()
    .then((response) => {
      // console.log(response.data);
      if (response.data.companies) {
        dispatch(setCompanies(response.data.companies));
      } else {
        console.log("Error fetching companies");
        dispatch(resetCompanies());
      }
    })
    .catch(() => {
      dispatch(resetCompanies());
    });

export const attemptGetAllUsersInfos = () => (dispatch: Dispatch) =>
    getUsersInfos()
      .then((response) => {
        if (response.data.users) {
          dispatch(setUsers(response.data.users));
        } else {
          console.log("Error fetching users");
          dispatch(resetUsers());
        }
      })
      .catch(() => {
        dispatch(resetUsers());
      });