import { login, logout, User } from "../actions/user";
import { Dispatch } from "redux";
import { Credentials } from "../actions/user";

import {
  postUser,
  postLogin,
  postLogout,
  getConfirmation,
  resendConfirmation,
  // resetRegister,
  sendResetPasswordLink,
  resetPassword,
} from "../../api/index";

export const attemptLogin = (credentials: Credentials) => (dispatch: Dispatch) =>
  postLogin(credentials).then(({ data }) => {
    dispatch(login(data.user));
  });

export const attemptSendResetPasswordLink = (email: string) => (dispatch: Dispatch) => sendResetPasswordLink(email);

export const attemptResetPassword = (password: string, token: string) => (dispatch: Dispatch) => resetPassword(password, token);

export const attemptLogout = () => (dispatch: Dispatch) =>
  postLogout()
    .then(() => {
      dispatch(logout());
    })
    .catch(() => {console.log("[Action] Error logging out")});

export const attemptRegister = (newUser: User) => () => postUser(newUser);

export const attemptGetConfirmation = (token: string) => (dispatch: Dispatch) => getConfirmation(token);

export const attemptResendConfirmation = (email: string) => (dispatch: Dispatch) => resendConfirmation(email)

// export const attemptResetRegister = (email: string) => (dispatch: Dispatch) => resetRegister(email)