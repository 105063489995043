import { useState } from "react";
import { Container, Modal, Button, Table, Form } from "react-bootstrap";
import { useAppDispatch } from "../../store/hooks";
import { LeadClass } from "../../store/actions/company";
import { attemptAddLeadType, attemptGetCompany } from "../../store/thunks/company";

export interface IProps {
  leadTypeClass: string;
  companyId: string;
}

export default function CompanyAddLeadType({ leadTypeClass, companyId }: IProps) {
  const dispatch = useAppDispatch();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [saveLeadTypeError, setSaveLeadTypeError] = useState<string>();

  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const [newLeadType, setNewLeadType] = useState<LeadClass>({
    leadType: leadTypeClass,
    webhook: "",
  });
  const handleCancelAddLeadType = () => setNewLeadType({ leadType: leadTypeClass, webhook: "" });
  const handleSaveAddLeadType = async () => {
    if (newLeadType && newLeadType.leadType !== "" && newLeadType.webhook !== "" && companyId) {
      setSaveLeadTypeError(undefined);
        let newLeadClass: any = { companyId: companyId, leadType: newLeadType.leadType, webhook: newLeadType.webhook };
        await dispatch(attemptAddLeadType(newLeadClass)).catch(() => { setSaveLeadTypeError("Error adding lead type. Please contact Cashifi if this problem persists."); });
        await dispatch(attemptGetCompany(companyId));
        handleCloseAddModal();
        setNewLeadType({leadType: leadTypeClass, webhook: ""});
    } else {
      setSaveLeadTypeError("Please fill out all company fields.");
    }
  };

  return (
    <Container>
      <Modal show={showAddModal} onHide={() => { handleCancelAddLeadType(); handleCloseAddModal(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Lead Type {leadTypeClass}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {saveLeadTypeError ? <div className="alert alert-danger" role="alert">{saveLeadTypeError}</div> : <></>}
          <Table responsive>
            <tbody>
              <tr className="admin-modal-tablerow">
                <td><Form.Label id="admin-form-label">Webhook:</Form.Label></td>
                <td><Form.Control placeholder="Company webhook" value={newLeadType?.webhook} onChange={(value) => setNewLeadType({ leadType: newLeadType.leadType, webhook: value.target.value })} /></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={async () => {await handleSaveAddLeadType();}}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Button id="admin-btn" style={{ width: "60%" }} onClick={() => { handleShowAddModal(); }}>Add Lead Type {leadTypeClass}</Button>
    </Container>
  );
}