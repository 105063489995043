import { Dispatch } from "redux";
import { Company, CompanyLeadClass } from "../actions/company";
import { postCompany, deleteCompany, getCompany, putLeadClass, updateLeadClass, deleteLeadClass, testWebhook, updateCompanyInfo } from "../../api/index";
import { setCompany } from "../actions/admin";

export const attemptCreateCompany = (newCompany: Company) => () => postCompany(newCompany);

export const attemptDeleteCompany = (companyId: string) => () => deleteCompany(companyId);

export const attemptAddLeadType = (companyLeadClass: any) => () => putLeadClass(companyLeadClass);

export const attemptGetCompany = (companyId: string) => async (dispatch: Dispatch) =>
  await getCompany(companyId)
    .then((response) => {
      // console.log(response.data);
      if (response.data.company) {
        dispatch(setCompany(response.data.company));
      } else {
        console.log("Error fetching company");
      }
    });

export const attemptUpdateCompanyInfo = (updatedCompany: Company, companyId: string) => () => updateCompanyInfo(updatedCompany, companyId);

export const attemptUpdateLeadType = (updatedLeadClass: CompanyLeadClass) => () => updateLeadClass(updatedLeadClass);

export const attemptDeleteLeadType = (companyId: string, leadTypeClass: string) => () => deleteLeadClass(companyId, leadTypeClass);

export const attemptTestWebhook = (webhook: string) => () => testWebhook(webhook);