import { combineReducers } from "redux";
import userReducer from "./user";
import adminReducer from "./admin";

const buildRootReducer = () =>
  combineReducers({
    user: userReducer,
    admin: adminReducer,
  });

export default buildRootReducer;