import { useState, useEffect } from "react";
import { useAppDispatch } from "../store/hooks";
import { attemptGetUser } from "../store/thunks/user";
import { Container, Row, Col } from "react-bootstrap";
import BusinessApplyFormParent from "../components/ApplyForm/FormParent";

export default function Home() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(attemptGetUser())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return loading ? (
    <p>Loading</p>
  ) : (
    <div className="page">
      <div className="jumbotron">
        <Container>
          <Row>
            <Col className="jb-col" md={10}>
              <h1>Cashifi's Easy Online Application</h1>
              <h4>To see if you qualify to receive funding for any business loan,<br />begin by filling out the form below.</h4>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="main">
        <BusinessApplyFormParent />
      </Container>
    </div>
  );
}
