import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";

export interface IProps {
  toggle: any;
}

export default function AdminNavbar({ toggle }: IProps) {
  return (
    <Navbar
      className="p-3 mb-5"
      expand
    >
      <Button variant="outline-info" className="admin-nav-btn" onClick={toggle}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto" navbar>
          <Nav.Item><span className="pd-l-20"></span><span className="color-primary">Admin Panel</span></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}