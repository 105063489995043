import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { attemptGetUser } from "../../store/thunks/user";

import { attemptGetAllLeads, attemptGetAllCompanies, attemptGetAllUsersInfos } from "../../store/thunks/admin";
import { useServerError } from "../../hooks/useServerError";
import "./AdminPanelPage.css";
import Sidebar from "./PanelComponents/Sidebar";
import ContentPanel from "./PanelComponents/ContentPanel";

export default function AdminPanelPage() {
  const userIsAdmin = useAppSelector((state) => state.user.isAdmin);
  const { handleServerError } = useServerError();
  const [isOpen, setIsOpen] = useState(true);
  const [content, setContent] = useState("analytics");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { isSuperAdmin, isSystemAdmin, user } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(attemptGetUser())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);

  // Render once on load
  useEffect(() => {
    dispatch(attemptGetAllLeads())
      .catch(handleServerError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Render once on load
  useEffect(() => {
    dispatch(attemptGetAllCompanies())
      .catch(handleServerError)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    // Render once on load
    useEffect(() => {
      dispatch(attemptGetAllUsersInfos())
        .catch(handleServerError)
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const showContent = (newContent: string) => {
    setContent(newContent);
  }

  return loading ? (
    <p>Loading</p>
  ) : !userIsAdmin ? <p>Unauthorized access.</p> : (
    <div className="wrapper">
      <Sidebar 
        toggle={toggleOpen} 
        isOpen={isOpen} 
        email={user && user.email ? user.email : "Administrator"} 
        adminType={isSystemAdmin ? "System Admin" : (isSuperAdmin ? "Super Admin" : "Admin")}
        setContent={showContent}
        panelContent={content} />
      <ContentPanel toggle={toggleOpen} isOpen={isOpen} panelContent={content} />
    </div>
  );
}