import { useEffect, useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { LeadClass, CompanyLeadClass } from "../../store/actions/company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { attemptGetCompany, attemptUpdateLeadType, attemptDeleteLeadType, attemptTestWebhook } from "../../store/thunks/company";

export interface IProps {
  leadType: LeadClass;
  companyId: string;
}

export default function CompanyLeadTypeDataRow({ leadType, companyId }: IProps) {
  const dispatch = useAppDispatch();
  const adminCompaniesData = useAppSelector((state) => state.admin.companies);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [actionError, setActionError] = useState<string>();
  const [newWebhook, setNewWebhook] = useState<string>(leadType.webhook ?? "");
  const [leadTypeData, setLeadTypeData] = useState<LeadClass>({leadType: ""});
  const [testDisabled, setTestDisabled] = useState<boolean>(false);
  const handleCloseEditModal = () => { setShowEditModal(false); setActionError(undefined) };
  const handleShowEditModal = () => setShowEditModal(true);
  const handleCancelEdit = () => setNewWebhook(leadType.webhook ?? "");

  const handleDeleteLeadType = async () => {
    if(companyId) {
      await dispatch(attemptDeleteLeadType(companyId, leadType.leadType))
      .then(() => { setActionError(undefined); })
      .catch(() => { setActionError("Error deleting lead type. Please contact Cashifi if this problem persists."); });
      await dispatch(attemptGetCompany(companyId));
    } else {
      setActionError("Error. Company does not exist.");
    }
  };
  
  const handleSaveEdit = async () => {
    if (newWebhook !== "" && companyId) {
      setActionError(undefined);
      let updatedLead: CompanyLeadClass = { companyId: companyId, leadType: leadType.leadType, webhook: newWebhook };
      await dispatch(attemptUpdateLeadType(updatedLead)).catch(() => { setActionError("Error updating lead type. Please contact Cashifi if this problem persists."); });
      await dispatch(attemptGetCompany(companyId));
      handleCloseEditModal();
    } else {
      setActionError("Please enter a valid webhook.");
    }
  };

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const handleTestLeadType = async (wh: string) => {
    setTestDisabled(true);
    await dispatch(attemptTestWebhook(wh)).catch(() => { setActionError("Error testing webhook. Please contact Cashifi if this problem persists."); });
    await timeout(5000);
    setTestDisabled(false);
  }

  const handleActivateLeadType = async () => {
    let updatedLead: CompanyLeadClass = { companyId: companyId, leadType: leadType.leadType, isActive: true };
    await dispatch(attemptUpdateLeadType(updatedLead)).catch(() => { setActionError("Error updating lead type. Please contact Cashifi if this problem persists."); });
    await dispatch(attemptGetCompany(companyId));
    handleCloseEditModal();
  }


  const handleDeactivateLeadType = async () => {
    let updatedLead: CompanyLeadClass = { companyId: companyId, leadType: leadType.leadType, isActive: false };
    await dispatch(attemptUpdateLeadType(updatedLead)).catch(() => { setActionError("Error updating lead type. Please contact Cashifi if this problem persists."); });
    await dispatch(attemptGetCompany(companyId));
    handleCloseEditModal();
  }

  useEffect(() => {
    if(adminCompaniesData) {
      const foundCompany =  adminCompaniesData.find(c => c._id === companyId);
      if(foundCompany !== undefined && foundCompany.leadTypes) {
        const foundLeadData = foundCompany.leadTypes.find(t => t.leadType === leadType.leadType);
        if(foundLeadData)
          setLeadTypeData(foundLeadData);
      }
    }
  }, [adminCompaniesData, companyId, leadType.leadType, setLeadTypeData]);

  return (
    <tr>
      <Modal show={showEditModal} onHide={() => { handleCancelEdit(); handleCloseEditModal(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Webhook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {actionError ? <div className="alert alert-danger" role="alert">{actionError}</div> : <></>}
          <Table responsive>
            <tbody>
              <tr className="admin-modal-tablerow">
                <td><Form.Label id="admin-form-label">Webhook:</Form.Label></td>
                <td><Form.Control placeholder="Company webhook" value={newWebhook} onChange={(value) => setNewWebhook(value.target.value.trim())} /></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={async () => {await handleSaveEdit();}}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <td>{leadTypeData.leadType}</td>
      <td>{leadTypeData.webhook ?? ""} <FontAwesomeIcon icon={faEdit} className="admin-link" onClick={handleShowEditModal} /></td>
      <td>{!leadTypeData.isActive ? "Inactive" : "Active"}</td>
      <td className="text-center">
        {!leadTypeData.isActive ?
          <Button variant="success" onClick={async () => {await handleActivateLeadType();}}>Activate</Button>
          : <Button variant="warning" onClick={async () => {await handleDeactivateLeadType();}}>Deactivate</Button>}
      </td>
      <td className="text-center">
        <Button variant="secondary" onClick={() => {handleTestLeadType(leadTypeData.webhook ?? "");}} disabled={testDisabled}>Test</Button>
      </td>
      <td className="text-center">
        <Button variant="danger" onClick={async () => {await handleDeleteLeadType();}}>Remove</Button>
      </td>
    </tr>
  );
}